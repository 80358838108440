import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import {
  provideAnimations,
  provideNoopAnimations,
} from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideToastr } from 'ngx-toastr';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withViewTransitions()),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    provideAnimations(),
    provideNoopAnimations(),
    provideAnimationsAsync(),
    provideToastr({
      timeOut: 3000, // Duración predeterminada de las notificaciones
      positionClass: 'toast-top-center', // Posición de las notificaciones
      preventDuplicates: true, // Evita notificaciones duplicadas
      toastClass: 'ngx-toastr my-custom-toast',
    }),
  ],
};
