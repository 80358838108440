import { Component } from '@angular/core';
import { EmailService } from './services/email.service';
import { Contact } from './model/contact';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home-contact',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './home-contact.component.html',
  styleUrl: './home-contact.component.scss',
})
export class HomeContactComponent {
  public contact: Contact;
  constructor(
    private _emailService: EmailService,
    private _toastr: ToastrService
  ) {
    this.contact = {
      email: '',
      message: '',
      name: '',
      subject: '',
    };
  }

  public sendEmail(): void {
    console.log(this.contact);
    this._emailService.sendEmail(this.contact).subscribe((response) => {
      if (response == '200') {
        this._toastr.success('Email sent successfully', 'Success');
      } else {
        this._toastr.error('Error sending email', 'Error');
      }
    });
  }
}
