<div id="services" class="service-style-three pb-70">
  <div class="container">
    <div class="section-title">
      <h2>Servicios que proveemos</h2>
      <p>
        Tener el control y la planificación de cada etapa del proyecto es
        esencial para el éxito de todo el proceso.
      </p>
    </div>
    <div class="row">
      <div class="col-lg-4 col-sm-6">
        <div class="service-items">
          <i class="flaticon-ui"></i>
          <h3>Consultoría</h3>
          <p>
            En la visita técnica nuestros expertos determinan los requisitos
            para acometer el proyecto, hacen una valoración del lugar, sus
            condiciones físicas y sugieren al cliente posibles soluciones a los
            problemas técnicos que se presentan.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div
          class="service-items wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay=".2"
        >
          <i
            class="flaticon-folder"
            style="color: blueviolet; border: 0.1px"
          ></i>
          <h3>Diseño del proyecto</h3>
          <p>
            Nuestros expertos con los datos recopilados en la visita técnica
            hacen una selección de los materiales, la cantidad de personal
            necesario, los proveedores de los materiales y confeccionan el
            proyecto técnico para acometer la instalación.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div
          class="service-items wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay=".3"
        >
          <i class="flaticon-medical-app"></i>
          <h3>Cálculo de costos</h3>
          <p>
            En este proceso se tiene en cuenta la complejidad de la instalación,
            el grado de peligrosidad del trabajo, el costo de los materiales y
            su transportación, el grado de experiencia de los especialistas
            seleccionados para acometer el proyecto.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div
          class="service-items wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay=".4"
        >
          <i class="flaticon-writing"></i>
          <h3>Instalación</h3>
          <p>
            Contamos con un personal altamente calificado, de vasta experiencia
            en la acometida de proyectos complejos. Hacemos la instalación de
            forma amigable con el medio ambiente. Usted puede supervisar
            personalmente cada etapa .
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div
          class="service-items wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay=".5"
        >
          <i class="flaticon-java"></i>
          <h3>Planificación de mantenimientos</h3>
          <p>
            A cada proyecto se le asocia un mantenimiento periódico según las
            características del lugar, del proyecto y los materiales utilizados.
            Ofrecemos un servicio de mantenimiento preventivo para garantizar la
            durabilidad de la instalación.
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div
          class="service-items wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay=".6"
        >
          <i class="flaticon-video"></i>
          <h3>Garantía</h3>
          <p>
            Ofrecemos garantías de calidad de 10 años en todos nuestras obras.
            Cada componente o parte está garantizado por el fabricante y por
            nuestra empresa. Si algo falla, lo reparamos sin costo adicional. Le
            ofrecemos un servicio postventa los 365 días del año.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
