<div id="pricing" class="pb-100">
  <div id="contact" class="contact-section contact-bg ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 offset-lg-5">
          <div class="contact-form">
            <h2>¿Qué servicio nuestro necesitas?</h2>
            <div id="contactForm">
              <div class="row">
                <div class="col-md-12 col-sm-6">
                  <div class="form-group mb-3">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      class="form-control"
                      placeholder="Nombre"
                      [(ngModel)]="contact.name"
                    />
                  </div>
                </div>
                <div class="col-md-12 col-sm-6">
                  <div class="form-group mb-3">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      class="form-control"
                      placeholder="Correo"
                      [(ngModel)]="contact.email"
                    />
                  </div>
                </div>
                <div class="col-md-12 col-md-12">
                  <div class="form-group mb-3">
                    <input
                      type="text"
                      name="msg_subject"
                      id="msg_subject"
                      class="form-control"
                      placeholder="Asunto"
                      [(ngModel)]="contact.subject"
                    />
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                  <div class="form-group mb-3">
                    <textarea
                      name="message"
                      class="message-field"
                      id="message"
                      cols="30"
                      rows="5"
                      placeholder="Mensaje"
                      [(ngModel)]="contact.message"
                    ></textarea>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12">
                  <button (click)="sendEmail()" class="default-btn contact-btn">
                    Enviar mensaje
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
